<template>
  <div>
    <v-form ref="form" :readonly="value.id > 0 && !canEditSetupTables" @submit.prevent="onSubmit">
      <v-container>
        <v-row>
          <v-col cols="12" md="12" class="px-0">
            <v-btn @click="cancel(false)"> cancel </v-btn>
            <v-btn v-if="canEditSetupTables" class="float-right" color="green" dark type="submit"> save </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-card>
        <v-container>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="newThirdPartyPaymentOption.name"
                label="Third Party Payment Name"
                :rules="[required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from '@/util/rules';

export default {
  data: () => ({
    required,
    id: null,
    saved: false,
    newThirdPartyPaymentOption: {
      name: '',
    },
  }),
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
  },
  methods: {
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.$emit('closeForm');
      }
    },
    onSubmit() {
      if (!this.$refs.form.validate()) {
        this.$myalert.error('Please complete all required fields', true);
        return;
      }
      this.$emit('submit', { id: this.id, ...this.newThirdPartyPaymentOption });
      this.saved = true;
    },
    compareValues() {
      if (!this.id && this.newThirdPartyPaymentOption.name) return true;

      if (!this.id) return false;

      return this.newThirdPartyPaymentOption.name !== this.value.name;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => this.newThirdPartyPaymentOption,
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && !this.saved) {
          this.newThirdPartyPaymentOption = { ...value };
        } else if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        }
      },
    },
    newThirdPartyPaymentOption: {
      deep: true,
      handler: function (value) {
        value.id = this.id;
        this.$emit('formChanges', value);
      },
    },
  },
};
</script>
