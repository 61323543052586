var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { readonly: _vm.value.id > 0 && !_vm.canEditSetupTables },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "px-0", attrs: { cols: "12", md: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.cancel(false)
                            },
                          },
                        },
                        [_vm._v(" cancel ")]
                      ),
                      _vm.canEditSetupTables
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "float-right",
                              attrs: {
                                color: "green",
                                dark: "",
                                type: "submit",
                              },
                            },
                            [_vm._v(" save ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Third Party Payment Name",
                              rules: [_vm.required],
                            },
                            model: {
                              value: _vm.newThirdPartyPaymentOption.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newThirdPartyPaymentOption,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "newThirdPartyPaymentOption.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }